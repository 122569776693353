import { Select } from "@chakra-ui/react";
import LabelAndCustomSelect from "../CommonComponents/CustomInputWithLabel/LabelAndCustomSelect";
import { filterOptions } from "../Utility/Util";
import { useState } from "react";
import { STRINGS } from "../Utility/StringsEn";

const QualityOfServiceSection = ({ renderingData, onChange }) => {
  const videoQualityOptions = [
    {
      label: "High",
      value: "high",
      isSelected: false,
    },
    {
      label: "Medium",
      value: "medium",
      isSelected: false,
    },
    {
      label: "Low",
      value: "low",
      isSelected: false,
    },
  ];
  const [
    filteredNumberOfParticipantsOptions,
    setFilteredNumberOfParticipantsOptions,
  ] = useState(
    filterOptions(videoQualityOptions, renderingData?.video_quality)
  );
  return (
    <div className="w-full flex flex-col items-start justify-start gap-[1.5rem] max-w-[77.5rem] text-left text-[1.25rem] text-darkslategray-200 font-heebo mq1366-1281:gap-[1.5rem] mq1280-961:gap-[1.5rem] Tablet:gap-[1.25rem] Mobile:gap-[1.5rem] small_mobile:gap-[1rem]">
      <b className="h-[1.375rem] relative tracking-[0.02em] leading-[1.375rem] inline-block mq1366-1281:text-[1.125rem] mq1366-1281:leading-[1.25rem] mq1280-961:text-[1.125rem] mq1280-961:leading-[1.25rem] Tablet:text-[1.125rem] Tablet:leading-[1.25rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] small_mobile:text-[0.875rem] small_mobile:leading-[1rem]">
        Quality of Service
      </b>
      <div className="w-full overflow-hidden flex flex-row items-center justify-start gap-[1.5rem] max-w-[22.5rem] text-[1rem] Mobile:flex-col Mobile:gap-[0.25rem] Mobile:items-start Mobile:justify-start Mobile:max-w-[37.5rem] small_mobile:flex-col small_mobile:gap-[0.25rem] small_mobile:items-start small_mobile:justify-start">
        <LabelAndCustomSelect
          label={STRINGS.videoQuality}
          renderingOptions={videoQualityOptions}
          fieldName={"video_quality"}
          onChange={(data) => {
            setFilteredNumberOfParticipantsOptions(
              filterOptions(videoQualityOptions, data.target.value)
            );
            onChange({
              ...renderingData,
              video_quality: data.target.value,
            });
          }}
          value={renderingData.video_quality}
        />
      </div>
    </div>
  );
};

export default QualityOfServiceSection;
