import { Switch } from "@chakra-ui/react";
import LabelAndCustomSelect from "../CommonComponents/CustomInputWithLabel/LabelAndCustomSelect";
import { STRINGS } from "../Utility/StringsEn";

const AutoScalingServersSection = ({ renderingData }) => {
  const numberOfParticipantsOptions = [
    {
      label: "0",
      value: 0,
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
    {
      label: "8",
      value: 8,
    },
    {
      label: "9",
      value: 9,
    },
    {
      label: "10",
      value: 10,
    },
    {
      label: "Default",
      value: 4,
    },
  ];
  return (
    <div className="disabled:opacity-75 w-full flex flex-col items-start justify-start gap-[1.5rem] max-w-[77.5rem] text-left text-[1.25rem] text-darkslategray-200 font-heebo mq1366-1281:gap-[1.5rem] mq1280-961:gap-[1.5rem] Tablet:gap-[1.25rem] Mobile:gap-[1.5rem] small_mobile:gap-[1.25rem]">
      <div className="self-stretch flex flex-row items-center justify-start gap-[0.375rem] Tablet:gap-[0.375rem] Mobile:gap-[0.25rem] small_mobile:gap-[0.25rem]">
        <b className="h-[1.375rem] relative tracking-[0.02em] leading-[1.375rem] inline-block mq1366-1281:text-[1.125rem] mq1366-1281:leading-[1.25rem] mq1280-961:text-[1.125rem] mq1280-961:leading-[1.25rem] Tablet:text-[1.125rem] Tablet:leading-[1.25rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] small_mobile:text-[0.875rem] small_mobile:leading-[1rem]">
          {STRINGS.autoScaling}
        </b>
        <div className="h-[1rem] relative text-[0.875rem] tracking-[0.02em] leading-[1rem] inline-block Tablet:text-[1.25rem] Tablet:leading-[1.375rem] Mobile:text-[0.875rem] Mobile:leading-[1rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
          ({STRINGS.enterpriseEdition})
        </div>
      </div>
      <div className="self-stretch grid flex-row items-center justify-start gap-[1.5rem] grid-cols-[repeat(2,_1fr)] text-[1rem] Tablet:grid Tablet:grid-cols-[repeat(1_,1fr)] Mobile:grid Mobile:grid-cols-[repeat(1,_1fr)] small_mobile:grid small_mobile:grid-cols-[repeat(1_,1fr)]">
        <div className="flex-1 overflow-hidden flex flex-row items-center justify-between max-w-[22.5rem] Mobile:gap-[0.25rem] Mobile:items-start Mobile:justify-start Mobile:max-w-[37.5rem] small_mobile:flex-col small_mobile:gap-[0.25rem] small_mobile:items-start small_mobile:justify-start">
          <div className="flex flex-row items-center justify-start max-w-[20rem] Mobile:min-w-[16.875rem]">
            <div className="flex flex-row items-center justify-start">
              <div className="w-[8.063rem] relative tracking-[-0.02em] font-medium inline-block shrink-0 mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
                {STRINGS.autoScaling}
              </div>
            </div>
          </div>
          <Switch
            className="relative opacity-100"
            colorScheme="aa"
            checked={false}
            disabled
            name={"auto_scaling"}
          />
        </div>
        <div className="flex-1 overflow-hidden flex flex-row items-center justify-between max-w-[360px] Mobile:gap-[8px] Mobile:items-start Mobile:justify-start Mobile:max-w-[600px] small_mobile:flex-col small_mobile:gap-[4px] small_mobile:items-start small_mobile:justify-start">
          <LabelAndCustomSelect
            label="Number of Servers"
            renderingOptions={numberOfParticipantsOptions}
            fieldName={"number_of_servers"}
            isFieldDisabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoScalingServersSection;
