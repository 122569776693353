import { ADVANCED_FEATURES_CARD } from "../Constants/AdminSettingsConstants";
import AdvancedFeatureCard from "./AdvancedFeatureCard";

const AdvancedFeaturesList = ({
  renderingData,
  onChange,
  isTranscriptSelected,
}) => {
  const dependentFeatures = ["call_summary", "catchup_meeting"];
  return (
    <div className="self-stretch grid flex-col items-start justify-start gap-[1.5rem] grid-cols-[repeat(2,_1fr)] [column-gap:20px] text-left text-[1rem] text-darkslategray-200 font-heebo md:grid md:grid-cols-[repeat(1,_1fr)] md:[column-gap:20px] Mobile:gap-[1rem] Mobile:grid Mobile:grid-cols-[repeat(1,_1fr)] Mobile:[column-gap:20px] small_mobile:gap-[1rem]">
      {ADVANCED_FEATURES_CARD.map((featureCard, index) => {
        let isEnabled =
          isTranscriptSelected || !dependentFeatures.includes(featureCard.name);

        return (
          <AdvancedFeatureCard
            key={index}
            icon={featureCard.icon}
            label={featureCard.label}
            isChecked={renderingData?.[featureCard.name]}
            isDisabled={!isEnabled}
            name={featureCard.name}
            onChange={onChange}
            renderingData={renderingData}
          />
        );
      })}
    </div>
  );
};

export default AdvancedFeaturesList;
