import { useCallback } from "react";
import { STRINGS } from "../Utility/StringsEn";

const Footer = () => {
  const onIconLinkedinClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/springct/");
  }, []);

  const onIconInstaClick = useCallback(() => {
    window.open("https://www.instagram.com/spring.ct/");
  }, []);

  const onIconTwitterClick = useCallback(() => {
    window.open("https://twitter.com/SpringCTI/");
  }, []);

  const onFbIconsContainerClick = useCallback(() => {
    window.open("https://www.facebook.com/SpringComputingTechnologies/");
  }, []);
  return (
    <div className="self-stretch bg-gray-200 flex flex-row items-center justify-center py-4 px-2.5 z-[0] text-left text-2xs text-gray-800 font-heebo Tablet:pl-9 Tablet:pt-4 Tablet:pr-9 Tablet:box-border">
      <div className="w-[1240px] flex flex-row items-center justify-between max-w-[1240px] Mobile:flex-col Mobile:items-center Mobile:justify-start Mobile:gap-[12px] small_mobile:flex-col small_mobile:gap-[12px]">
        <div className="flex flex-col items-center justify-center gap-[2px]">
          <div className="relative leading-[14px]">
            {STRINGS.anOfferingFrom}
          </div>
          <div className="w-[122px] flex flex-row items-start justify-start">
            <div className="flex flex-col items-start justify-start">
              <img
                className="w-[114px] relative h-[26px] object-cover"
                alt=""
                src="/ic-logo-w-1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-row items-center justify-center py-0 px-5 text-center text-xs Mobile:flex-[unset] Mobile:self-stretch small_mobile:flex-[unset] small_mobile:self-stretch">
          <div className="flex-1 relative leading-[18px]">
            {STRINGS.copyrightText.replace("{year}", new Date().getFullYear())}
          </div>
        </div>
        <div className="w-[116px] flex flex-row items-center justify-end gap-[5px]">
          <button
            className="cursor-pointer [border:none] p-0 bg-[transparent] w-5 h-5 flex flex-col items-center justify-center gap-[10px] [&_.iconlinkedin-white]:hover:flex [&_.iconlinkedin-grey]:hover:hidden"
            onClick={onIconLinkedinClick}
          >
            <img
              className="iconlinkedin-grey w-[16.7px] relative h-[14.4px]"
              alt=""
              src="/iconlinkedin-grey.svg"
            />
            <img
              className="iconlinkedin-white w-[16.7px] relative h-[14.4px] hidden"
              alt=""
              src="/iconlinkedin-white.svg"
            />
          </button>
          <button
            className="cursor-pointer [border:none] p-0 bg-[transparent] w-5 h-5 flex flex-col items-center justify-center gap-[10px] [&_.instawhite-icon]:hover:flex [&_.instagrey-icon]:hover:hidden sm:[&_.instawhite-icon]:hover:flex sm:[&_.instagrey-icon]:hover:hidden"
            onClick={onIconInstaClick}
          >
            <img
              className="instagrey-icon instagrey-icon w-[15.2px] relative h-[13.2px]"
              alt=""
              src="/instagrey.svg"
            />
            <img
              className="instawhite-icon instawhite-icon w-[15.2px] relative h-[13.2px] hidden"
              alt=""
              src="/instawhite.svg"
            />
          </button>
          <button
            className="cursor-pointer [border:none] p-2.5 bg-[transparent] w-5 h-5 flex flex-col items-center justify-center box-border gap-[10px] [&_.twitterwhite-icon]:hover:flex [&_.twittergrey-icon]:hover:hidden"
            onClick={onIconTwitterClick}
          >
            <img
              className="twittergrey-icon w-[15px] h-4 relative hover:hidden"
              alt=""
              src="/twittergrey.svg"
            />
            <img
              className="twitterwhite-icon w-[15px] h-4 relative hidden hover:flex"
              alt=""
              src="/twitterwhite.svg"
            />
          </button>
          <div
            className="flex flex-col items-center justify-start cursor-pointer [&_.iconfb-hover]:hover:flex [&_.iconfb]:hover:hidden"
            onClick={onFbIconsContainerClick}
          >
            <button className="iconfb cursor-pointer [border:none] p-0 bg-[transparent] w-5 h-5 flex flex-col items-center justify-center">
              <img
                className="w-2.5 relative h-[17px] sm:mix-blend-normal sm:hover:mix-blend-normal"
                alt=""
                src="/shape.svg"
              />
            </button>
            <button className="iconfb-hover cursor-pointer [border:none] p-0 bg-[transparent] w-5 h-5 hidden flex-col items-center justify-center">
              <img
                className="w-2.5 relative h-[17px] sm:mix-blend-normal sm:hover:mix-blend-normal"
                alt=""
                src="/shape1.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
