import { Switch } from "@chakra-ui/react";
import { STRINGS } from "../Utility/StringsEn";

const AdvancedFeatureCard = ({
  icon,
  label,
  isChecked,
  onChange,
  isDisabled,
  name,
}) => {
  return (
    <div className="self-stretch rounded-md bg-whitesmoke-100 flex flex-row items-center justify-between py-[0.938rem] px-[1rem] text-left text-[1rem] text-darkslategray-200 font-heebo mq1366-1281:p-[0.75rem] mq1366-1281:box-border mq1280-961:p-[0.75rem] mq1280-961:box-border Tablet:p-[0.75rem] Tablet:box-border Mobile:pt-[0.75rem] Mobile:pr-[1rem] Mobile:pb-[0.75rem] Mobile:box-border small_mobile:h-auto small_mobile:pt-[0.75rem] small_mobile:pr-[1rem] small_mobile:pb-[0.75rem] small_mobile:box-border small_mobile:max-w-[15rem]">
      <div className="flex-1 flex flex-row items-center justify-start gap-[1rem]">
        <img
          className="w-[2.625rem] relative h-[2.625rem] overflow-hidden shrink-0 object-cover Tablet:w-[2.25rem] Tablet:h-[2.25rem] Mobile:w-[1.875rem] Mobile:h-[1.875rem] small_mobile:w-[1.5rem] small_mobile:h-[1.5rem]"
          alt=""
          src={icon}
        />
        <div className="flex-1 flex flex-row items-center justify-start">
          <div className="flex-1 relative tracking-[0.02em] leading-[1.125rem] capitalize font-medium Tablet:text-[1rem] Tablet:leading-[1.125rem] Mobile:text-[1rem] Mobile:leading-[1.125rem] small_mobile:text-[0.875rem] small_mobile:leading-[1rem]">
            <p className="m-0">{label}</p>
            <p className="m-0">({STRINGS.enterpriseEdition})</p>
          </div>
        </div>
      </div>
      <Switch
        colorScheme="aa"
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChange}
        name={name}
      />
    </div>
  );
};

export default AdvancedFeatureCard;
