import { useCallback, useEffect, useState } from "react";
import Header from "../Header/Header";
import TopNavigation from "../CommonComponents/TopNavigation/TopNavigation";
import MeetingsParticipantsSection from "./MeetingsParticipantsSection";
import AutoScalingServersSection from "./AutoScalingServersSection";
import QualityOfServiceSection from "./QualityOfServiceSection";
import FeaturesContainer from "./FeaturesContainer";
import AdvancedFeaturesContainer from "./AdvancedFeaturesContainer";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import CustomPrimaryButton from "../CommonComponents/CustomButton/CustomPrimaryButton";
import {
  getAdminSettings,
  setAdminSettings,
} from "../Redux/Actions/AdminSettingsActions";
import { useToast } from "@chakra-ui/react";
import { STRINGS } from "../Utility/StringsEn";
import { useNavigate } from "react-router-dom";
import { USER_PLANS } from "../Constants/AdminSettingsConstants";

const AdminSettings = () => {
  const dispatch = useDispatch();
  const userPlan = useSelector((state) => state?.UserReducer?.plan)
  const navigate = useNavigate();
  const [configData, setConfigData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    dispatch(
      getAdminSettings((data) => {
        setConfigData(data?.configurations);
      })
    );
  }, []);

  const setConfiguration = () => {
    dispatch(
      setAdminSettings({ configurations: configData }, (response) => {
        if (response?.code === 200) {
          navigate("/dashboard");
          toast({
            title: STRINGS.settingsAppliedSuccessfully,
            status: "success",
            variant: "subtle",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => { },
          });
        } else {
          toast({
            title: response.error.message,
            status: "error",
            variant: "subtle",
            duration: 3000,
            isClosable: true,
          });
        }
      })
    );
  };
  return (
    <div className="w-full h-screen relative bg-whitesmoke-200 flex flex-col items-center justify-start mq1280-961:items-start mq1280-961:justify-start">
      <Header />
      <div className="self-stretch flex-1 bg-whitesmoke-200 flex flex-col items-center justify-start pt-[1.25rem] px-[6rem] pb-[1.875rem] gap-[0.5rem] z-[1] md:pl-[2.25rem] md:pr-[2.25rem] md:box-border Tablet:py-[1rem] Tablet:px-[2.125rem] Tablet:box-border Mobile:bg-white Mobile:gap-[0.75rem] Mobile:py-[0.75rem] Mobile:px-[1rem] Mobile:box-border small_mobile:bg-white small_mobile:gap-[0.75rem] small_mobile:py-[0.75rem] small_mobile:px-[1rem] small_mobile:box-border">
        <TopNavigation />

        <div className="w-full bg-white flex flex-col items-center justify-start py-[1rem] px-[1.25rem] box-border gap-[2.25rem] max-w-[77.5rem] Mobile:gap-[1.5rem] Mobile:py-[0.375rem] Mobile:px-[0rem] Mobile:box-border small_mobile:gap-[1.5rem] small_mobile:items-start small_mobile:justify-start small_mobile:py-[0.375rem] small_mobile:px-[0rem] small_mobile:box-border">
          {configData && (
            <>
              <MeetingsParticipantsSection
                onChange={(data) => {
                  setConfigData((prevState) => ({
                    ...prevState,
                    meeting_and_participants: data,
                  }));
                }}
                renderingData={configData?.meeting_and_participants}
              />
              <AutoScalingServersSection
                onChange={(data) =>
                  setConfigData((prevState) => ({
                    ...prevState,
                    auto_scaling: data,
                  }))
                }
                renderingData={configData?.auto_scaling}
              />
              <QualityOfServiceSection
                onChange={(data) =>
                  setConfigData((prevState) => ({
                    ...prevState,
                    quality_of_service: data,
                  }))
                }
                renderingData={configData?.quality_of_service}
              />
              <div className="w-full flex flex-col items-center justify-start gap-[3.125rem] max-w-[77.5rem] mq1366-1281:gap-[1.5rem] Mobile:gap-[2.25rem] small_mobile:gap-[1.5rem]">
                <FeaturesContainer
                  onChange={(data) => {
                    // if transcript is selected then enable call_summary and catchup_meeting
                    if (data.hasOwnProperty("transcript")) {
                      if (!data.transcript) {
                        setConfigData((prevState) => {
                          return {
                            ...prevState,
                            advance_features: {
                              ...prevState.advance_features,
                              call_summary: false,
                              catchup_meeting: false,
                            },
                          };
                        });
                      }
                    }
                    setConfigData((prevState) => {
                      return {
                        ...prevState,
                        features: {
                          ...prevState.features,
                          ...data,
                        },
                      };
                    });
                  }}
                  renderingData={configData?.features}
                />
                {
                  USER_PLANS.ENTERPRISE === userPlan && configData?.advance_features &&
                  <AdvancedFeaturesContainer
                    onChange={(data) => {
                      setConfigData((prevState) => {
                        return {
                          ...prevState,
                          advance_features: {
                            ...prevState.advance_features,
                            ...data,
                          },
                        };
                      });
                    }}
                    renderingData={configData?.advance_features}
                    isTranscriptSelected={configData?.features?.transcript}
                  />
                }
              </div>
              <div className="self-stretch flex justify-end align-end">
                <CustomPrimaryButton
                  btnLabel={STRINGS.applyButton}
                  onClick={setConfiguration}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminSettings;
