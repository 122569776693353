import { useNavigate } from "react-router-dom";
import { MenuList, MenuItem, MenuDivider } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { userLogout } from "../../Redux/Actions/UserActions";
import { STRINGS } from "../../Utility/StringsEn";

const DropdownOptions = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(
      userLogout(() => {
        navigate("/");
      })
    );
  };
  return (
    <MenuList
      outline={0}
      p={0}
      bg="white"
      className="relative flex flex-col items-start justify-start min-w-[180px] max-w-full max-h-full overflow-auto text-left text-sm text-gray-300 font-heebo"
    >
      <MenuItem
        p={3}
        className="self-stretch flex flex-row items-center justify-start"
        _hover={{ bg: "#e9e9e9" }}
        onClick={() => navigate("/settings")}
      >
        <b className="relative leading-[14px]">{STRINGS.settings}</b>
      </MenuItem>
      <MenuDivider
        p={0}
        m={0}
        className="self-stretch bg-whitesmoke-500 h-px"
      />
      <MenuItem
        p={3}
        className="self-stretch flex flex-row items-center justify-start"
        _hover={{ bg: "#e9e9e9" }}
        onClick={onLogout}
      >
        <b className="relative leading-[14px]">{STRINGS.logout}</b>
      </MenuItem>
    </MenuList>
  );
};

export default DropdownOptions;
