import { Select } from "@chakra-ui/react";
import LabelAndCustomSelect from "../CommonComponents/CustomInputWithLabel/LabelAndCustomSelect";
import { useState } from "react";
import { filterOptions } from "../Utility/Util";
import { STRINGS } from "../Utility/StringsEn";

const MeetingsParticipantsSection = ({ renderingData, onChange }) => {
  const meetingTimeOptions = [
    { label: "15 mins", value: 15, isSelected: false },
    { label: "30 mins", value: 30, isSelected: false },
    { label: "45 mins", value: 45, isSelected: false },
    { label: "1 hr", value: 60, isSelected: false },
    { label: "1 hr 30 mins", value: 90, isSelected: false },
    { label: "2 hrs", value: 120, isSelected: false },
    { label: "Default", value: 10, isSelected: false },
  ];
  const numberOfParticipantsOptions = [
    {
      label: "0",
      value: 0,
      isSelected: false,
    },
    {
      label: "1",
      value: 1,
      isSelected: false,
    },
    {
      label: "2",
      value: 2,
      isSelected: false,
    },
    {
      label: "3",
      value: 3,
      isSelected: false,
    },
    {
      label: "4",
      value: 4,
      isSelected: false,
    },
    {
      label: "5",
      value: 5,
      isSelected: false,
    },
    {
      label: "6",
      value: 6,
      isSelected: false,
    },
    {
      label: "7",
      value: 7,
      isSelected: false,
    },
    {
      label: "8",
      value: 8,
      isSelected: false,
    },
    {
      label: "9",
      value: 9,
      isSelected: false,
    },
    {
      label: "10",
      value: 10,
      isSelected: false,
    },
    {
      label: "20",
      value: 20,
      isSelected: false,
    },
    {
      label: "30",
      value: 30,
      isSelected: false,
    },
    {
      label: "Default",
      value: 4,
      isSelected: false,
    },
  ];
  const [filteredMeetingTimeOptions, setFilteredMeetingOptions] = useState(
    filterOptions(meetingTimeOptions, "value", renderingData?.max_meeting_time)
  );

  const [
    filteredNumberOfParticipantsOptions,
    setFilteredNumberOfParticipantsOptions,
  ] = useState(
    filterOptions(
      numberOfParticipantsOptions,
      "value",
      renderingData?.number_of_participents
    )
  );
  return (
    <div className="w-full flex flex-col items-start justify-start gap-[1.5rem] max-w-[77.5rem] text-left text-[1.25rem] text-darkslategray-200 font-heebo mq1366-1281:gap-[1.5rem] mq1280-961:gap-[1.5rem] Tablet:gap-[1.25rem] Mobile:gap-[1.5rem] small_mobile:gap-[1.25rem]">
      <div className="self-stretch grid flex-row items-center justify-start gap-[1.5rem] grid-cols-[repeat(2_,1fr)] text-[1rem] Tablet:grid Tablet:grid-cols-[repeat(1_,1fr)] Mobile:grid Mobile:grid-cols-[repeat(1_,1fr)] small_mobile:grid small_mobile:grid-cols-[repeat(1_,1fr)]">
        <LabelAndCustomSelect
          label={STRINGS.maxMeetingTimes}
          renderingOptions={filteredMeetingTimeOptions}
          fieldName={"max_meeting_time"}
          onChange={(data) => {
            setFilteredMeetingOptions(
              filterOptions(
                filteredMeetingTimeOptions,
                "value",
                data.target.value
              )
            );
            onChange({
              ...renderingData,
              max_meeting_time: Number(data.target.value),
            });
          }}
          value={renderingData.max_meeting_time}
        />
        <div className="flex-1 overflow-hidden flex flex-row items-center justify-between max-w-[22.5rem] Mobile:flex-col Mobile:gap-[0.25rem] Mobile:items-start Mobile:justify-start Mobile:max-w-[37.5rem] small_mobile:flex-col small_mobile:gap-[0.25rem] small_mobile:items-start small_mobile:justify-start">
          <LabelAndCustomSelect
            label={STRINGS.numberOfParticipants}
            renderingOptions={filteredNumberOfParticipantsOptions}
            fieldName={"number_of_participents"}
            onChange={(data) => {
              setFilteredNumberOfParticipantsOptions(
                filterOptions(
                  filteredNumberOfParticipantsOptions,
                  "value",
                  Number(data.target.value)
                )
              );

              onChange({
                ...renderingData,
                number_of_participents: Number(data.target.value),
              });
            }}
            value={renderingData.number_of_participents}
          />
        </div>
      </div>
    </div>
  );
};

export default MeetingsParticipantsSection;
