const CustomPrimaryButton = ({ btnLabel, onClick = null }) => {
  return (
    <button
      onClick={onClick}
      className="cursor-pointer [border:none] py-[18px] px-5 bg-mediumseagreen w-[180px] rounded-6xs h-[52px] flex flex-row items-center justify-center box-border min-w-[140px] hover:bg-mediumspringgreen Tablet:w-40 Mobile:self-stretch Mobile:w-full small_mobile:self-stretch small_mobile:w-auto"
    >
      <b className="w-[129px] relative text-sm leading-[14px] uppercase inline-block font-heebo text-gray-200 text-center shrink-0">
        {btnLabel}
      </b>
    </button>
  );
};

export default CustomPrimaryButton;
