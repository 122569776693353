import { useContext } from "react";
import { StatisticsContext } from "../AdminDashboard";
import { STRINGS } from "../../Utility/StringsEn";
import moment from "moment";

const StatCardsContainer = () => {
  const contextData = useContext(StatisticsContext);

  return (
    <div className="self-stretch rounded-xl bg-darkslategray-300 overflow-hidden flex flex-row items-start justify-start py-2.5 px-3 gap-[20px] text-left text-5xl text-white font-heebo Tablet:gap-[20px_16px] Tablet:py-5 Tablet:px-4 Tablet:box-border Tablet:grid Tablet:grid-cols-[repeat(3,_1fr)] Tablet:[row-gap:16px] Tablet:[column-gap:16px] Tablet:auto-rows-[1fr] Mobile:py-4 Mobile:px-3 Mobile:box-border Mobile:grid Mobile:grid-cols-[repeat(2,_1fr)] Mobile:[row-gap:8px] Mobile:[column-gap:8px] small_mobile:py-3.5 small_mobile:px-2.5 small_mobile:box-border small_mobile:grid small_mobile:grid-cols-[repeat(1,_1fr)] small_mobile:[row-gap:4px] small_mobile:[column-gap:4px] small_mobile:auto-rows-[1fr]">
      <div className="self-stretch flex-1 rounded-xl flex flex-col items-start justify-start p-2.5 box-border gap-[4px] h-full">
        <div className="flex flex-row items-end justify-start">
          <div className="relative leading-[26px] font-medium mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-3xl Tablet:leading-[24px] Mobile:text-lg Mobile:leading-[20px] small_mobile:text-base small_mobile:leading-[18px]">
            {contextData?.participents_count}
          </div>
        </div>
        <div className="self-stretch relative text-sm leading-[16px] font-medium Tablet:text-sm Tablet:leading-[16px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-xs small_mobile:leading-[14px]">
          {contextData?.participents_count > 1
            ? STRINGS.currentUsers
            : STRINGS.currentUser}
        </div>
      </div>
      <div className="self-stretch flex-1 rounded-xl flex flex-col items-start justify-start p-2.5 box-border gap-[4px] h-full">
        <div className="flex flex-row items-end justify-start">
          <div className="relative leading-[26px] font-medium mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-3xl Tablet:leading-[24px] Mobile:text-lg Mobile:leading-[20px] small_mobile:text-base small_mobile:leading-[18px]">
            {contextData?.sessions_count}
          </div>
        </div>
        <div className="self-stretch relative text-sm leading-[16px] font-medium Tablet:text-sm Tablet:leading-[16px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-xs small_mobile:leading-[14px]">
          {contextData?.sessions_count > 1
            ? STRINGS.numberOfMeetings
            : STRINGS.numberOfMeeting}
        </div>
      </div>
      <div className="self-stretch flex-1 rounded-xl flex flex-col items-start justify-start p-2.5 box-border gap-[4px] h-full">
        <div className="flex flex-row items-end justify-start">
          <div className="relative leading-[26px] font-medium mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-3xl Tablet:leading-[24px] Mobile:text-lg Mobile:leading-[20px] small_mobile:text-base small_mobile:leading-[18px]">
            {contextData?.disk_usage}
          </div>
        </div>
        <div className="self-stretch relative text-sm leading-[16px] font-medium Tablet:text-sm Tablet:leading-[16px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-xs small_mobile:leading-[14px]">
          <p className="m-0">{STRINGS.storage} </p>
        </div>
      </div>
      {/* 
      <div className="self-stretch flex-1 rounded-xl flex flex-col items-start justify-start p-2.5 box-border gap-[4px] h-full">
        <div className="flex flex-row items-end justify-start">
          <div className="relative leading-[26px] font-medium mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-3xl Tablet:leading-[24px] Mobile:text-lg Mobile:leading-[20px] small_mobile:text-base small_mobile:leading-[18px]">
           {contextData?.peak_load}
          </div>
        </div>
        <div className="self-stretch relative text-sm leading-[16px] font-medium Tablet:text-sm Tablet:leading-[16px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-xs small_mobile:leading-[14px]">
         <p className="m-0">{STRINGS.peakLoad}</p>
          <p className="m-0">({STRINGS.concurrentMeetings})</p> 
        </div>
      </div>
      <div className="self-stretch flex-1 rounded-xl flex flex-col items-start justify-start p-2.5 box-border gap-[4px] h-full">
        <div className="flex flex-row items-end justify-start">
          <div className="relative leading-[26px] font-medium mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-3xl Tablet:leading-[24px] Mobile:text-lg Mobile:leading-[20px] small_mobile:text-base small_mobile:leading-[18px]">
            {contextData?.bandwidth_consumption} GBPS
          </div>
        </div>
        <div className="self-stretch relative text-sm leading-[16px] font-medium Tablet:text-sm Tablet:leading-[16px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-xs small_mobile:leading-[14px]">
          <p className="m-0">{STRINGS.bandwidth}</p>
          <p className="m-0">{STRINGS.consumptionPerDay}</p>
        </div>
      </div>
      */}
      <div className="self-stretch flex-1 rounded-xl bg-darkslategray-100 flex flex-col items-start justify-start p-2.5 box-border gap-[4px] h-full">
        <div className="flex flex-row items-end justify-start">
          <div className="relative leading-[26px] font-medium mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-3xl Tablet:leading-[24px] Mobile:text-lg Mobile:leading-[20px] small_mobile:text-base small_mobile:leading-[18px]">
            $ {contextData?.awsEstimatedCost?.amount ? contextData?.awsEstimatedCost?.amount : ''}
          </div>
        </div>
        <div className="self-stretch relative text-sm leading-[16px] font-medium Tablet:text-sm Tablet:leading-[16px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-xs small_mobile:leading-[14px]">
          {`Usage Cost 
          ${contextData?.awsEstimatedCost?.timePeriod.start
              ? `(${contextData?.awsEstimatedCost?.timePeriod.start ?
                moment(contextData?.awsEstimatedCost?.timePeriod.start).format('DD MMM') + ' - ' + moment(contextData?.awsEstimatedCost?.timePeriod.end).format('DD MMM')
                : ''})` : ''}`}
        </div>
      </div>
    </div>
  );
};

export default StatCardsContainer;
