import { useMemo } from "react";

const PageHeading = ({ title, titleHeight, titleLineHeight }) => {
  const titleStyle = useMemo(() => {
    return {
      height: titleHeight,
      lineHeight: titleLineHeight,
    };
  }, [titleHeight, titleLineHeight]);

  return (
    <b
      className="h-[26px] flex-1 relative tracking-[0.02em] leading-[26px] inline-block mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-xl Tablet:leading-[22px] Mobile:text-xl Mobile:leading-[22px] small_mobile:text-lg small_mobile:leading-[20px]"
      style={titleStyle}
    >
      {title}
    </b>
  );
};

export default PageHeading;
