export const METHOD = {
  HTTP: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
  },
};

export const API_STATUS = {
  SUCCESS: 200,
  SUCCESS_NO_CONTENT: 204,
  NETWORK_ERROR: 600,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  UNSUPPORTED_MEDIA: 415,
  INTERNAL_SERVER: 500,
  NOT_FOUND: 404,
};
