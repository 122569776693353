import { Switch } from "@chakra-ui/react";

const FeatureCard = ({
  icon,
  label,
  isChecked,
  onChange,
  isDisabled,
  name,
}) => {
  return (
    <div className="self-stretch rounded-md bg-whitesmoke-100 flex flex-row items-center justify-between py-[15px] px-4 text-left text-base text-darkslategray-200 font-heebo Mobile:pt-3 Mobile:pr-4 Mobile:pb-3 Mobile:box-border small_mobile:h-auto small_mobile:pt-3 small_mobile:pr-4 small_mobile:pb-3 small_mobile:box-border small_mobile:max-w-[240px]">
      <div className="flex-1 flex flex-row items-center justify-start py-0 pr-2 pl-0">
        <div className="flex-1 flex flex-row items-center justify-start gap-[16px]">
          <img
            className="w-[42px] relative h-[42px] overflow-hidden shrink-0 object-cover Tablet:w-9 Tablet:h-9 Mobile:w-7 Mobile:h-7 small_mobile:w-6 small_mobile:h-6"
            alt=""
            src={icon}
          />
          <div className="flex-1 flex flex-row items-center justify-start">
            <div className="flex-1 relative tracking-[0.02em] leading-[18px] capitalize font-medium Tablet:text-base Tablet:leading-[18px] Mobile:text-base Mobile:leading-[18px] small_mobile:text-sm small_mobile:leading-[16px]">
              {label}
            </div>
          </div>
        </div>
      </div>
      <Switch
        colorScheme="aa"
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChange}
        name={name}
      />
    </div>
  );
};

export default FeatureCard;
