import { useCallback, useState } from "react";
import { Alert, TextField } from "@mui/material";
import { API_STATUS } from "../Constants/AppConstants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../Redux/Actions/UserActions";
import { useToast } from "@chakra-ui/react";
import { STRINGS } from "../Utility/StringsEn";

const LoginForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [loginForm, setLoginForm] = useState({ userName: "", password: "" });

  const onButtonSendClick = useCallback(
    (e) => {
      e.preventDefault();
      if (loginForm?.userName && loginForm?.password) {
        dispatch(
          userLogin(loginForm, (response) => {
            if (response?.status === API_STATUS.SUCCESS) {
              navigate("/dashboard");
            } else {
              toast({
                title: "Username or Password is incorrect.",
                status: "error",
                variant: "subtle",
                duration: 3000,
                isClosable: true,
              });
            }
          })
        );
      } else {
        toast({
          title: "Please enter Username and Password.",
          status: "error",
          variant: "subtle",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [onSubmit, loginForm]
  );

  const handleOnChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };
  return (
    <form
      onSubmit={onButtonSendClick}
      className="flex-1 bg-white flex flex-col items-center justify-start py-0 px-3 gap-[20px] text-left text-xl text-gray-300 font-heebo mq1366-1281:gap-[16px_20px] mq1280-961:gap-[16px_20px] Tablet:gap-[20px] Tablet:pl-24 Tablet:pt-0 Tablet:pr-24 Tablet:box-border Tablet:flex-[unset] Tablet:self-stretch Mobile:h-auto Mobile:gap-[24px_20px] Mobile:items-center Mobile:justify-start Mobile:pt-3 Mobile:px-4 Mobile:pb-6 Mobile:box-border Mobile:flex-[unset] Mobile:self-stretch small_mobile:gap-[24px_20px] small_mobile:pl-3 small_mobile:pr-3 small_mobile:box-border small_mobile:flex-[unset] small_mobile:self-stretch"
    >
      <img
        className="w-[232px] max-h-full object-contain Tablet:max-h-[60px] Mobile:max-h-[50px] small_mobile:h-[30px]"
        alt=""
        src="/logo@2x.png"
      />
      <b className="self-stretch h-4 relative tracking-[0.02em] leading-[16px] inline-block text-darkslategray-200 sm:text-xl sm:leading-[28px] mq1366-1281:text-lg mq1366-1281:leading-[20px] mq1280-961:text-lg mq1280-961:leading-[20px] Tablet:text-5xl Tablet:leading-[26px] Mobile:text-xl Mobile:leading-[22px] small_mobile:text-xl">
        {STRINGS.login}
      </b>
      <div className="self-stretch flex flex-col items-start justify-center gap-[8px] text-base Tablet:gap-[4px_8px] Mobile:flex-col Mobile:gap-[4px_8px] Mobile:items-start Mobile:justify-center small_mobile:flex-col small_mobile:gap-[4px_8px] small_mobile:items-start small_mobile:justify-start">
        <div className="relative leading-[18px] mq1366-1281:text-sm mq1366-1281:leading-[16px] mq1280-961:text-sm mq1280-961:leading-[16px] Mobile:text-base Mobile:leading-[18px] small_mobile:text-base small_mobile:leading-[18px]">
          {STRINGS.userName}
        </div>
        <TextField
          className="[border:none] bg-[transparent] self-stretch font-heebo text-lg text-gray-600 sm:min-w-0 Mobile:h-[50px] Mobile:max-h-[42px] small_mobile:h-[42px] small_mobile:pl-3 small_mobile:pr-3 small_mobile:box-border small_mobile:max-h-[36px]"
          color="primary"
          size="small"
          placeholder={STRINGS.enterUserName}
          fullWidth={true}
          variant="outlined"
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          value={loginForm?.userName}
          name="userName"
          onChange={handleOnChange}
          required
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-center gap-[8px] text-base Tablet:gap-[4px_8px] Mobile:flex-col Mobile:gap-[4px_8px] Mobile:items-start Mobile:justify-center small_mobile:flex-col small_mobile:gap-[4px_8px] small_mobile:items-start small_mobile:justify-start">
        <div className="relative leading-[18px] mq1366-1281:text-sm mq1366-1281:leading-[16px] mq1280-961:text-sm mq1280-961:leading-[16px] Mobile:text-base Mobile:leading-[18px] small_mobile:text-base small_mobile:leading-[18px]">
          {STRINGS.password}
        </div>
        <TextField
          className="[border:none] bg-[transparent] self-stretch font-heebo text-lg text-gray-600 sm:min-w-0 Mobile:h-[50px] Mobile:max-h-[42px] small_mobile:h-[42px] small_mobile:pl-3 small_mobile:pr-3 small_mobile:box-border small_mobile:max-h-[36px]"
          color="primary"
          size="small"
          placeholder={STRINGS.enterPassword}
          fullWidth={true}
          variant="outlined"
          type="password"
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          value={loginForm?.password}
          name="password"
          onChange={handleOnChange}
          required
        />
      </div>
      <div className="w-[540px] hidden flex-col items-start justify-center gap-[8px] Mobile:flex-col Mobile:gap-[8px] Mobile:items-start Mobile:justify-start small_mobile:flex-col small_mobile:gap-[6px_8px] small_mobile:items-start small_mobile:justify-start">
        <div className="relative leading-[22px] Mobile:text-base Mobile:leading-[18px] small_mobile:text-base">
          Type
        </div>
        <div className="flex flex-row items-center justify-start gap-[36px] Mobile:flex-row Mobile:gap-[36px_12px] Mobile:items-start Mobile:justify-start small_mobile:flex-row small_mobile:gap-[36px_12px] small_mobile:items-start small_mobile:justify-start">
          <div className="flex flex-row items-center justify-start gap-[16px] Mobile:gap-[16px_8px] small_mobile:gap-[16px_4px]">
            <input
              className="cursor-pointer m-0 w-6 relative h-6"
              id="login-type"
              type="radio"
              name="login-type"
              background-color="#1ed07e"
            />
            <div className="relative leading-[22px] Mobile:text-base Mobile:leading-[18px] small_mobile:text-base">
              Demo
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[16px] Mobile:gap-[16px_8px] small_mobile:gap-[16px_4px]">
            <input
              className="cursor-pointer m-0 w-6 relative h-6"
              id="login-type"
              type="radio"
              name="login-type"
            />
            <div className="relative leading-[22px] Mobile:text-base Mobile:leading-[18px] small_mobile:text-base">
              Admin
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-end justify-start Tablet:self-stretch Tablet:w-auto">
        <button
          className="cursor-pointer [border:none] py-[18px] px-5 bg-mediumseagreen w-[249px] rounded-6xs h-[52px] flex flex-row items-center justify-center box-border min-w-[140px] hover:bg-mediumspringgreen Tablet:self-stretch Tablet:w-auto Mobile:self-stretch Mobile:w-auto Mobile:h-[50px] Mobile:min-w-[100px] small_mobile:self-stretch small_mobile:w-auto small_mobile:h-12 small_mobile:min-w-[30px]"
          onClick={onButtonSendClick}
        >
          <div className="flex flex-row items-center justify-center gap-[12px]">
            <b className="relative text-xl leading-[14px] uppercase font-heebo text-gray-200 text-center mq1366-1281:text-sm mq1366-1281:leading-[16px] mq1280-961:text-sm mq1280-961:leading-[16px] Tablet:text-xl Tablet:leading-[22px] Mobile:text-sm Mobile:leading-[16px] small_mobile:text-xs small_mobile:leading-[14px]">
              {STRINGS.loginButton}
            </b>
            <img
              className="w-[13.4px] relative h-2"
              alt=""
              src="/vector3.svg"
            />
          </div>
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
