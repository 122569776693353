import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../Utility/StringsEn";

const TopNavigation = () => {
  const navigate = useNavigate();

  const onIcBackArrowClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="w-full flex flex-row items-center justify-start gap-[10px] max-w-[1240px] text-left text-5xl text-darkslategray-200 font-heebo">
      <button
        className="cursor-pointer [border:none] py-3 px-[5px] bg-[transparent] w-10 h-10 flex flex-col items-center justify-center box-border"
        onClick={onIcBackArrowClick}
      >
        <img
          className="w-[29px] relative h-[26.4px]"
          alt=""
          src="./union.svg"
        />
      </button>
      <b className="h-[26px] flex-1 relative tracking-[0.02em] leading-[26px] inline-block mq1366-1281:text-3xl mq1366-1281:leading-[24px] mq1280-961:text-3xl mq1280-961:leading-[24px] Tablet:text-xl Tablet:leading-[22px] Mobile:text-xl Mobile:leading-[22px] small_mobile:text-lg small_mobile:leading-[20px]">
        {STRINGS.settings}
      </b>
    </div>
  );
};

export default TopNavigation;
