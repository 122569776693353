import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";

import MediaServerConatiner from "../../Dashboard/MediaServerInstances/MediaServerConatiner";
import TurnServerContainer from "../../Dashboard/MediaServerInstances/TurnServerContainer";
import { STRINGS } from "../../Utility/StringsEn";

function CustomTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs className="self-stretch bg-white text-xl">
      <TabList>
        <Tab className="flex flex-row items-center justify-center pt-[0.625rem] px-[2.25rem] pb-[0.75rem] text-left text-xl font-heebo">
          <div className="relative text-darkslategray-200 tracking-[-0.02em] font-medium mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
            {STRINGS.mediaServer}
          </div>
        </Tab>
        <Tab className="flex flex-row items-center justify-center pt-[0.625rem] px-[2.25rem] pb-[0.75rem] text-left text-xl font-heebo">
          <div className="relative text-darkslategray-200 tracking-[-0.02em] font-medium mq1366-1281:text-[0.875rem] mq1280-961:text-[0.875rem] Tablet:text-[0.875rem] Mobile:text-[0.875rem] small_mobile:text-[0.75rem] small_mobile:leading-[0.875rem]">
            {STRINGS.turnServer}
          </div>
        </Tab>
      </TabList>
      <TabIndicator mt="-2px" height="3px" bg="mediumseagreen" />
      <TabPanels>
        <TabPanel>
          <MediaServerConatiner />
        </TabPanel>
        <TabPanel>
          <TurnServerContainer />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default CustomTabs;
