export const STRINGS = {
  login: "Login",
  register: "Register",
  forgotPassword: "Forgot Password",
  resetPassword: "Reset Password",
  dashboard: "Dashboard",
  usageStatistics: "Usage Statistics",
  instances: "Instances",

  // BUTTON LABELS
  loginButton: "LOGIN",
  applyButton: "APPLY",
  mediaServer: "Media Server",
  turnServer: "Turn Server",

  // PLACEHOLDER TEXTS
  search: "Search..",
  enterUserName: "Enter Username",
  enterPassword: "Enter Password",

  // LABELS
  userName: "Username",
  password: "Password",
  email: "Email",
  confirmPassword: "Confirm Password",
  settings: "Settings",
  logout: "Logout",
  currentUser: "Current User",
  currentUsers: "Current Users",
  numberOfMeeting: "Number of Meeting",
  numberOfMeetings: "Number of Meetings",
  peakLoad: "Peak Load",
  concurrentMeetings: "Concurrent Meetings",
  bandwidth: "Bandwidth",
  consumptionPerDay: "Consumption/Day",
  usageCost: "Usage Cost",
  storage: "Storage",
  gb: "GB",
  mb: "MB",
  srNo: "Sr. No.",
  ip: "IP",
  cpu: "CPU",
  memory: "Memory",
  meetings: "Meetings",
  remainingCapacity: "Remaining Capacity",
  healthCheck: "Health Check",
  status: "Status",
  runTime: "Run Time",
  creationTime: "Creation Time",
  lastServedAt: "Last Served At",
  maxMeetingTimes: "Max Meeting Times",
  numberOfParticipants: "Number of Participants",
  autoScaling: "Auto Scaling",
  numberOfServers: "Number of Servers",
  qualityOfService: "Quality of Service",
  videoQuality: "Video Quality",
  features: "Features",
  advanceFeatures: "Advance Features",
  audioCalls: "Audio Calls",
  videoCalls: "Video Calls",
  liveChat: "Live Chat",
  clientSideRecording: "Client Side Recording",
  serverSideRecording: "Server Side Recording",
  waitingRoom: "Waiting Room",
  screenSharing: "Screen Sharing",
  desktopStreaming: "Desktop Streaming",
  moderatorControl: "Moderator Control",
  transcript: "Transcript",
  autoCentering: "Auto-Centering",
  callSummary: "Call Summary",
  catchUpMeeting: "Catch-Up Meeting",
  pipScreenSharing: "PIP Screen Sharing",
  enterpriseEdition: "Enterprise Edition",

  // FOOTER
  anOfferingFrom: "An offering from",
  copyrightText: "© {year} Spring Computing Technologies, All Rights Reserved.",
  privacyPolicy: "Privacy Policy",

  // ERROR MESSAGES
  // SUCCESS MESSAGES
  settingsAppliedSuccessfully: "Settings applied successfully.",
  // INFO MESSAGES

  // MISC
  hhmm: "hh:mm",
  noDataFound: "No Data Found",
  healthy: "Healthy",
  unhealthy: "Unhealthy",
};
