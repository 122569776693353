import {
  SET_PLAN,
  SET_USER_LOGIN,
  SET_USER_LOGOUT,
  USER_LOGOUT,
} from "../../Actions/ActionTypes";

const INITIAL_STATE = {
  userData: null,
  token: null,
  error: null,
  plan: null
};
export default function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER_LOGIN:
      return {
        ...state,
        userData: action?.data?.userName,
        error: action?.data?.data === null ? { ...action?.data?.error } : null,
        token: action?.data?.token,
      };
    case SET_USER_LOGOUT:
      return INITIAL_STATE;
    case SET_PLAN:
      return {
        ...state,
        plan: action?.data?.plan
      }
    default:
      return state;
  }
}
