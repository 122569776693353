import { useContext } from "react";
import StatCardsContainer from "./StatCardsContainer";
import { StatisticsContext } from "../AdminDashboard";
import { STRINGS } from "../../Utility/StringsEn";

const UsageStatisticsContainer = () => {
  const contextData = useContext(StatisticsContext);
  return (
    <div className="w-full bg-white overflow-hidden flex flex-col items-start justify-start py-3 px-5 box-border gap-[4px] max-w-[1240px] text-left text-xl text-darkslategray-200 font-heebo Tablet:gap-[4px] Mobile:gap-[6px_4px] Mobile:py-2.5 Mobile:px-0 Mobile:box-border small_mobile:gap-[4px] small_mobile:pl-0 small_mobile:pr-0 small_mobile:box-border">
      <div className="self-stretch flex flex-row items-start justify-between Mobile:flex-col Mobile:items-start Mobile:justify-center Mobile:gap-[6px] small_mobile:flex-col small_mobile:items-start small_mobile:justify-start">
        <b className="h-[29px] flex-1 relative tracking-[0.02em] inline-block mq1366-1281:text-lg mq1366-1281:leading-[20px] mq1280-961:text-lg mq1280-961:leading-[20px] Tablet:text-lg Tablet:leading-[20px] Mobile:text-base Mobile:leading-[18px] Mobile:flex-[unset] Mobile:self-stretch small_mobile:text-sm small_mobile:leading-[16px] small_mobile:flex-[unset] small_mobile:self-stretch">
          {STRINGS.usageStatistics}
        </b>
        {/* <div className="flex flex-row items-center justify-start gap-[8px] text-sm Tablet:gap-[8px_16px] Mobile:gap-[8px_12px] small_mobile:gap-[8px]">
          <div className="h-4 relative tracking-[0.02em] leading-[16px] font-medium inline-block mq1366-1281:text-xs mq1366-1281:leading-[14px] mq1280-961:text-xs mq1280-961:leading-[14px] Tablet:text-xs Tablet:leading-[14px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-3xs small_mobile:leading-[12px]">
            {STRINGS.storage}
          </div>
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <div className="rounded-md bg-whitesmoke-300 flex flex-row items-center justify-center p-1">
              <div className="h-4 relative tracking-[0.02em] leading-[16px] font-medium inline-block mq1366-1281:text-xs mq1366-1281:leading-[14px] mq1280-961:text-xs mq1280-961:leading-[14px] Tablet:text-xs Tablet:leading-[14px] Mobile:text-xs Mobile:leading-[14px] small_mobile:text-3xs small_mobile:leading-[12px]">
                {contextData?.disk_usage}
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <StatCardsContainer />
    </div>
  );
};

export default UsageStatisticsContainer;
