import { useDispatch, useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import { setUserLogin, userLogin } from "../Redux/Actions/UserActions";
import { API_STATUS } from "../Constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { Alert } from "@mui/material";

const Login = () => {
  return (
    <div className="w-full h-screen relative bg-whitesmoke-200 flex flex-col items-center justify-center py-0 px-[34px] box-border text-left text-13xl text-darkslategray-200 font-heebo Tablet:items-center Tablet:justify-start Tablet:pl-0 Tablet:pr-0 Tablet:box-border Mobile:items-center Mobile:justify-start Mobile:pl-0 Mobile:pr-0 Mobile:box-border small_mobile:items-center small_mobile:justify-start small_mobile:pl-0 small_mobile:pr-0 small_mobile:box-border">
      <div className="w-full rounded-2xl bg-white shadow-[2px_4px_8px_rgba(0,_0,_0,_0.32)] flex flex-row items-center justify-center p-6 box-border gap-[24px] max-w-[960px] z-[1] mq1600-1367:gap-[24px_32px] mq1366-1281:gap-[24px] mq1366-1281:max-w-[820px] mq1280-961:flex-row mq1280-961:gap-[24px] mq1280-961:max-w-[820px] Tablet:flex-col Tablet:gap-[20px_24px] Tablet:items-center Tablet:justify-start Tablet:rounded-none Tablet:p-0 Tablet:box-border Tablet:h-screen Mobile:flex-1 Mobile:flex-col Mobile:gap-[12px_24px] Mobile:items-center Mobile:justify-start Mobile:rounded-none Mobile:p-0 Mobile:box-border small_mobile:flex-1 small_mobile:flex-col small_mobile:gap-[12px_24px] small_mobile:items-center small_mobile:justify-start small_mobile:rounded-none small_mobile:pt-0 small_mobile:px-0 small_mobile:pb-4 small_mobile:box-border">
        <div className="flex-1 flex flex-col items-center justify-center p-2.5 box-border bg-[url('/public/graphics@3x.png')] bg-cover bg-no-repeat bg-[top] min-h-[500px] mq1366-1281:min-h-[400px] mq1366-1281:max-h-[400px] mq1280-961:min-h-[400px] mq1280-961:max-h-[400px] Tablet:h-auto Tablet:pt-2.5 Tablet:box-border Tablet:min-h-[400px] Tablet:max-h-[260px] Tablet:flex-[unset] Tablet:self-stretch Mobile:gap-[10px] Mobile:items-center Mobile:justify-start Mobile:pl-2.5 Mobile:pt-0 Mobile:pr-2.5 Mobile:box-border Mobile:min-h-[220px] Mobile:flex-[unset] Mobile:self-stretch small_mobile:pl-2.5 small_mobile:box-border small_mobile:max-w-[297px] small_mobile:min-h-[220px] small_mobile:max-h-[220px] small_mobile:flex-[unset] small_mobile:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-contain Tablet:flex Tablet:max-w-[840px] Tablet:max-h-[380px] Mobile:flex Mobile:flex-row Mobile:items-center Mobile:justify-center Mobile:max-h-[220px] Mobile:object-contain small_mobile:flex small_mobile:gap-[10px] small_mobile:items-center small_mobile:justify-center small_mobile:pl-0 small_mobile:box-border"
            alt=""
            src="/frame-1261152679@2x.png"
          />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
